import * as React from "react";
import { Link } from "gatsby";
import {
  makeStyles,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import "fontsource-roboto";

import Header from "../components/Header";

const theme = createMuiTheme({
  palette: {
    background: {
      default: "#efefef",
      height: "100%",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: "10px",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

const IndexPage = () => {
  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Header />
      <Container className={classes.root}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Paper
              square
              variant={"outlined"}
              elevation={2}
              className={classes.paper}
            >
              Hi I'm Jake! <br />
              Read my <Link to="/blog">blog</Link>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper
              square
              variant={"outlined"}
              elevation={2}
              className={classes.paper}
            >
              Tech I'm familiar with
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper
              square
              variant={"outlined"}
              elevation={2}
              className={classes.paper}
            >
              Tech I'm interested in
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper
              square
              variant={"outlined"}
              elevation={2}
              className={classes.paper}
            >
              Get in touch
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
};

export default IndexPage;
